class Configuration {

  ADDRESSES_URL = "https://acc.api.zipster.care/SPP/administration/v1/addresses";
  AUDIT_EVENTS_URL = "https://acc.api.zipster.care/SPP/administration/v1/auditevents";
  CONSENTS_URL = "https://acc.api.zipster.care/SPP/administration/v1/consents";
  ENCOUNTERS_URL = "https://acc.api.zipster.care/SPP/administration/v1/encounters";
  GPS_URL = "https://acc.api.zipster.care/SPP/administration/v1/generalpractitioners";
  PRACTITIONERS_URL = "https://acc.api.zipster.care/SPP/administration/v1/practitioners";
  ORGANIZATIONS_URL = "https://acc.api.zipster.care/SPP/administration/v1/organizations";
  PATIENTS_URL = "https://acc.api.zipster.care/SPP/administration/v1/patients";
  PRACTITIONERROLES_URL = "https://acc.api.zipster.care/SPP/administration/v1/practitionerroles";
  QUESTIONNAIRES_URL = "https://acc.api.zipster.care/SPP/administration/v1/questionnaires";
  QUESTIONNAIRE_ITEMS_URL = "https://acc.api.zipster.care/SPP/administration/v1/questionnaireitems";
  QUESTIONNAIRE_RESPONSES_URL = "https://acc.api.zipster.care/SPP/administration/v1/questionnaireresponses";
  QUESTIONNAIRE_RESPONSE_ITEMS_URL = "https://acc.api.zipster.care/SPP/administration/v1/questionnaireresponseitems";
  QUESTIONNAIRE_ANSWER_OPTION_URL = "https://acc.api.zipster.care/SPP/administration/v1/questionnaireansweroptions";
  SUGGESTIONS_URL = "https://acc.api.zipster.care/SPP/administration/v1/suggestions";
  SUGGESTION_CONCEPTS_URL = "https://acc.api.zipster.care/SPP/administration/v1/suggestioncodableconceptmappings";
  HEALTHCARESERVICES_URL = "https://acc.api.zipster.care/SPP/administration/v1/healthcareservices";
  HEALTHCARESERVICEAREAS_URL = "https://acc.api.zipster.care/SPP/administration/v1/healthcareserviceareas";
  CODABLECONCEPTS_URL = "https://acc.api.zipster.care/SPP/administration/v1/codableconcepts";
  CODABLECONCEPTCODES_URL = "https://acc.api.zipster.care/SPP/administration/v1/codableconceptcodes";
  CODABLECONCEPTMAPPINGS_URL = "https://acc.api.zipster.care/SPP/administration/v1/codableconceptmappings";
  SERVICEREQUESTS_URL = "https://acc.api.zipster.care/SPP/administration/v1/servicerequests";
  SERVICEREQUESTS_CONCEPTS_URL = "https://acc.api.zipster.care/SPP/administration/v1/servicerequestcodableconcepts";
  PROCEDURES_URL = "https://acc.api.zipster.care/SPP/administration/v1/procedures";
  MAILABLE_RESOURCES_URL = "https://acc.api.zipster.care/SPP/administration/v1/mailableresources";
  ASSIGNED_HEALTHCARE_SERVICES_URL = "https://acc.api.zipster.care/SPP/administration/v1/assignedhealthcareservices";
  RELATED_PERSONS_URL = "https://acc.api.zipster.care/SPP/administration/v1/relatedpersons";
  FOLLOWUPS_URL = "https://acc.api.zipster.care/SPP/administration/v1/followups";
  PLACE_OF_CONTACTS_URL = "https://acc.api.zipster.care/SPP/administration/v1/placeofcontacts";
  RELATIONSHIP_CONCEPTS_URL = "https://acc.api.zipster.care/SPP/administration/v1/relationshipconcepts";
  LANGUAGE_CONCEPTS_URL = "https://acc.api.zipster.care/SPP/administration/v1/languageconcepts";
  LOCATIONS_URL = "https://acc.api.zipster.care/SPP/administration/v1/locations";
  GROUPS_URL = "https://acc.api.zipster.care/SPP/administration/v1/groups";
  GROUP_PATIENTS_URL = "https://acc.api.zipster.care/SPP/administration/v1/grouppatients";
  FEEDBACK_URL = "https://acc.api.zipster.care/SPP/administration/v1/feedbacks";

  REGISTER_URL = "https://acc.api.zipster.care/SPP/administration/v1/organizationrequests";
  
  SERVICEREQUESTS_ANALYTICS = "https://acc.api.zipster.care/SPP/administration/v1/analytics/servicerequest";
  QUESTIONNAIRES_ANALYTICS = "https://acc.api.zipster.care/SPP/administration/v1/analytics/questionnaire";

  SOCIALE_KAART_URL = "https://us-central1-zipster-care.cloudfunctions.net/corsAgent/api/health-offers";
  SOCIALE_KAART_LEAFLET_URL = "https://us-central1-zipster-care.cloudfunctions.net/corsAgent/api/leaflet";
  BXL_SOCIAL_URL = "https://europe-west1-zipster-care.cloudfunctions.net/social-brussels/rest/organisation"; 

  REGISTRATION_QUESTIONNAIRE_ID = 'da968d59-2e42-4fde-b0ce-45a4a90bdc92';
  SOURCE_TRACING_QUESTIONNAIRE_ID = '8669a393-d35f-47a2-92a8-b0903c17a78e';
  SOCIAL_CONTEXT_QUESTIONNAIRE_ID = '6efebafd-6371-4396-8df2-b8a1b9a0c257';
  CASE_REPORT_QUESTIONNAIRE_ID = 'f706d5d7-df3f-4cb5-b757-4cda1c390e1a';

  RELATIONSHIPS_CODE_SYSTEM = 'https://www.zipster.care/relationships/nl';
  LANGUAGE_CODE_SYSTEM = 'https://www.zipster.care/languages/nl';

  SERVICE_REQUEST_INITIAL_STATUS = 'Imported';

  SERVICE_REQUEST_STATUS_PLANNED = 'PLANNED';
  SERVICE_REQUEST_STATUS_WAITING_LIST = 'WAITING LIST';
  SERVICE_REQUEST_STATUS_FORWARD = 'FORWARD';
  SERVICE_REQUEST_STATUS_NO_SUPPORT = 'NO SUPPORT';
  SERVICE_REQUEST_STATUS_SUPPORT_ENDED = 'SUPPORT ENDED';
  SERVICE_REQUEST_STATUS_SUPPORT = 'SUPPORT';
  SERVICE_REQUEST_STATUS_ALREADY_SUPPORTED = "ALREADY SUPPORTED";
  SERVICE_REQUEST_STATUS_NOT_REACHABLE = "NOT REACHABLE";
  SERVICE_REQUEST_STATUS_NO_SHOW = "NO SHOW";
  SERVICE_REQUEST_STATUS_NO_SUPPORT_WANTED = "NO SUPPORTED WANTED";
  SERVICE_REQUEST_STATUS_SIGNPOSTING = "FlyerMailSent";
  SERVICE_REQUEST_STATUS_SIGNPOSTING_FAILED = "SendFlyerMessageFailed";

  SERVICE_REQUEST_INTENT_SIGNPOSTING = "signposting";

  ENCOUNTER_CONSULT = "consult";
  ENCOUNTER_CONSULT_QUESTIONNAIRE = "consult-questionnaire";
  ENCOUNTER_CONSULT_NEEDS_BASED = "consult-needs-based";
  ENCOUNTER_CONSULT_DIRECT_REFERRAL = "consult-direct-referral";
  ENCOUNTER_FOLLOWUP = "followup";

  REGION_VLAANDEREN_ID = 'c281428e-9613-4b93-b94b-172dcb71a02f';

  CODABLE_CONCEPT_SYSTEM = 'https://www.zipster.care/coding';
  CODABLE_CONCEPT_PG_SYSTEM = 'https://www.zipster.care/coding/PG';

  PROXY_QUESTIONNAIRE = "Proxy";  
  ACTIVE_QUESTIONNAIRE = "Active";

  HYBRID_ORGANISATION = "Hybrid";

  BXL_SOCIAL = "bxlSocial";
  SOCIALE_KAART = "socialeKaart";

  NEEDS_CATEGORY_CARE = "https://www.zipster.care/categories/care";
  NEEDS_CATEGORY_FINANCE_HOUSING = "https://www.zipster.care/categories/financehousing";
  NEEDS_CATEGORY_LEISURE = "https://www.zipster.care/categories/leisure";
  NEEDS_CATEGORY_HEALTH= "https://www.zipster.care/categories/health";
  NEEDS_CATEGORY_SOCIAL_RELATIONAL= "https://www.zipster.care/categories/socialrelational";
  NEEDS_CATEGORY_MOBILITY_WORK = "https://www.zipster.care/categories/mobilitywork";
  NEEDS_CATEGORY_EDUCATION = "https://www.zipster.care/categories/education";
  NEEDS_CATEGORY_MENTAL_HEALTH = "https://www.zipster.care/categories/mentalhealth";
  NEEDS_CATEGORY_INCLUSION_SAFETY_CRISIS = "https://www.zipster.care/categories/inclusionsafetycrisis";
  NEEDS_CATEGORY_SKILLS = "https://www.zipster.care/categories/skills";

}

export default Configuration;